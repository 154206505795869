import axiosInstance from "auth/FetchInterceptor";

import { KIEM_KE_API } from "constants/ApiConstant";

const KiemKeMemberService = {};

KiemKeMemberService.GetMemberList = function (data) {
  return axiosInstance.get(`${KIEM_KE_API}/get-member-list?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeMemberService.GetById = function (data) {
  return axiosInstance.get(`${KIEM_KE_API}/get-member-by-id?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeMemberService.CreateMember = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/create-member`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeMemberService.UpdateMember = function (data) {
  return axiosInstance.put(`${KIEM_KE_API}/update-member`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeMemberService.DeleteMember = function (data) {
  return axiosInstance.delete(`${KIEM_KE_API}/delete-member?id=${data.id}&kiemKeId=${data.kiemKeId}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

export default KiemKeMemberService;
