import { PlusOutlined } from "@ant-design/icons";
import { Divider, Select, Button } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllDoiTuong } from "store/slices/khachhangSlice";
import ModalKhachHangNhaCungCap from "./ModalKhachHang_NCC";

const KhNccSelect = (props) => {
  const modalKhachHang_NCCRef = useRef();

  const dispatch = useDispatch();
  const [open, setOpen] = useState();
  const { DoiTuongList } = useSelector((state) => state.khachhang);
  const [datasaSrc, setDataSrc] = useState([]);

  useEffect(() => {
    let data = DoiTuongList;
    if (props.loaiDoiTuong === 1)
      // khach hang
      data = data.filter(
        (item) => item.loaiDoiTuong === 1 || item.loaiDoiTuong === 4
      );
    else if (props.loaiDoiTuong === 2)
      // ncc
      data = data.filter(
        (item) => item.loaiDoiTuong === 2 || item.loaiDoiTuong === 4
      );
    else if (props.loaiDoiTuong === 3) {
      // nhan vien
      data = data.filter((item) => item.loaiDoiTuong === 3);
    }
    setDataSrc(
      data.map((item) => ({
        label: item.tenDoiTuong,
        value: item.id,
        disabled: !item.trangThai,
      }))
    );
  }, [DoiTuongList, props.loaiDoiTuong]);


  
  useEffect(() => {
    dispatch(GetAllDoiTuong());
  }, [dispatch]);

  const openModal = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  const onAddNewSuccess = (data) => {
    dispatch(GetAllDoiTuong());
    if (props.onChange) props.onChange(data.id);
    if (props.onAddNew) props.onAddNew(data);
    setOpen(false);
  };
  return (
    <>
      <Select
        {...props}
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ width: "120px" }}
                type="primary"
                icon={<PlusOutlined />}
                onClick={openModal}
                disabled={props.loaiDoiTuong === 3}
              >
                Thêm
              </Button>
            </div>
          </>
        )}
        options={datasaSrc}
        onChange={props.onChange}
      ></Select>

      <ModalKhachHangNhaCungCap
        ref={modalKhachHang_NCCRef}
        open={open}
        setOpen={setOpen}
        Loai={props.loaiDoiTuong}
        // reloadData={reloadData}
        onSuccess={onAddNewSuccess}
        loaiDoiTuong={props.loaiDoiTuong}
      />
    </>
  );
};
export default KhNccSelect;
