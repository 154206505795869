import axiosInstance from "auth/FetchInterceptor";
import {
  NHA_SAN_XUAT_API,
} from "constants/ApiConstant";

const DmNhaSanXuatService = {};

DmNhaSanXuatService.Get = function () {
  return axiosInstance.get(`${NHA_SAN_XUAT_API}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

DmNhaSanXuatService.Create = function (data) {
  return axiosInstance.post(`${NHA_SAN_XUAT_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};




export default DmNhaSanXuatService;
