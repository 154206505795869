import { PHIEU_THU_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const PhieuThuService = {};

PhieuThuService.GetAllPhieuThu = function (data) {
  return axiosInstance.post(`${PHIEU_THU_API}/get-all-phieu-thu`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

PhieuThuService.GetPhieuThu = function (data) {
  return axiosInstance.get(`${PHIEU_THU_API}/get-phieu-thu?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

PhieuThuService.UpsertPhieuThu = function (data) {
  return axiosInstance.put(`${PHIEU_THU_API}/up-sert-phieu-thu`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

PhieuThuService.DeletePhieuThu = function (data) {
  return axiosInstance.delete(`${PHIEU_THU_API}/xoa-phieu-thu?id=${data}`, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

PhieuThuService.GetPhieuThuChiTiet = function (data) {
  return axiosInstance.get(
    `${PHIEU_THU_API}/get-phieu-thu-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

PhieuThuService.UpsertPhieuThuChiTiet = function (data) {
  return axiosInstance.put(
    `${PHIEU_THU_API}/up-sert-phieu-thu-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

PhieuThuService.DeletePhieuThuChiTiet = function (data) {
  return axiosInstance.delete(
    `${PHIEU_THU_API}/xoa-phieu-thu-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

PhieuThuService.ExportPhieuFinance = function (data) {
  return axiosInstance.post(`${PHIEU_THU_API}/export-excel`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

export default PhieuThuService;
