import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import DeNghiThuTienService from "services/Finance/DeNghiThuTienService";

export const GetAllDeNghiThuTien = createAsyncThunk(
  "dinhKhoan/GetAllDeNghiThuTien",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiThuTienService.GetAllDeNghiThuTien(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetDeNghiThuTien = createAsyncThunk(
  "dinhKhoan/GetDeNghiThuTien",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiThuTienService.GetDeNghiThuTien(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDeNghiThuTien = createAsyncThunk(
  "dinhKhoan/UpsertDeNghiThuTien",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiThuTienService.UpsertDeNghiThuTien(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteDeNghiThuTien = createAsyncThunk(
  "dinhKhoan/DeleteDeNghiThuTien",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiThuTienService.DeleteDeNghiThuTien(
        payload.id
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetDeNghiThuTienChiTiet = createAsyncThunk(
  "dinhKhoan/GetDeNghiThuTienChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiThuTienService.GetDeNghiThuTienChiTiet(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetChiDeNghiThuTienChiTiet = createAsyncThunk(
  "dinhKhoan/GetChiDeNghiThuTienChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiThuTienService.GetChiDeNghiThuTienChiTiet(
        data
      );
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDeNghiThuTienChiTiet = createAsyncThunk(
  "dinhKhoan/UpsertDeNghiThuTienChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiThuTienService.UpsertDeNghiThuTienChiTiet(
        data
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteDeNghiThuTienChiTiet = createAsyncThunk(
  "dinhKhoan/DeleteDeNghiThuTienChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiThuTienService.DeleteDeNghiThuTienChiTiet(
        payload.id
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const ExportDeNghiThu = createAsyncThunk(
  "dinhKhoan/ExportDeNghiThu",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiThuTienService.ExportPhieuFinance(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  DeNghiThuTienList: [],
  DeNghiThuTienDetail: {},
  DeNghiThuTienChiTietList: [],
  ChiDeNghiThuTienChiTietList: [],
};

const deNghiThuTienSlice = createSlice({
  name: "DeNghiThuTien",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetChiDeNghiThuTienChiTiet
      .addCase(GetChiDeNghiThuTienChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChiDeNghiThuTienChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.ChiDeNghiThuTienChiTietList = action.payload;
      })
      .addCase(GetChiDeNghiThuTienChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetDeNghiThuTienChiTiet
      .addCase(GetDeNghiThuTienChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDeNghiThuTienChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiThuTienChiTietList = action.payload;
      })
      .addCase(GetDeNghiThuTienChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetDeNghiThuTien
      .addCase(GetDeNghiThuTien.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetDeNghiThuTien.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiThuTienDetail = action.payload;
      })
      .addCase(GetDeNghiThuTien.rejected, (state) => {
        state.loading = false;
      })
      //GetAllDeNghiThuTien
      .addCase(GetAllDeNghiThuTien.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllDeNghiThuTien.fulfilled, (state, action) => {
        state.loading = false;
        state.DeNghiThuTienList = action.payload;
      })
      .addCase(GetAllDeNghiThuTien.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = deNghiThuTienSlice.actions;

export default deNghiThuTienSlice.reducer;
