import { isString } from "lodash";
import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  EXPIRE_TIME,
  USER_ROLES,
} from "constants/AuthConstant";

export const setAuthenData = (data) => {
  const token = data.tokenData.token;
  localStorage.setItem(AUTH_TOKEN, token);
  const refreshToken = data.tokenData.refreshToken;
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
  const expiredTime = data.tokenData.expires;
  localStorage.setItem(EXPIRE_TIME, expiredTime);
  // const firebaseToken = data.firebaseToken;
  // localStorage.setItem(FIREBASE_TOKEN, firebaseToken);
  localStorage.setItem(USER_ROLES, data.userRoles);
};

export const getExtension = (fileName, indexOf = 0) => {
  let fileExt = "";
  const dotLastIdx = fileName.lastIndexOf(".");
  if (dotLastIdx >= 0) fileExt = fileName.substring(dotLastIdx + indexOf);

  return fileExt;
};
export const makeRandomId = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
export const getNameWithoutExtension = (fileName) => {
  return fileName.substr(0, fileName.lastIndexOf("."));
};

export const formatInputMoney = (n) => {
  if (n <= 0) return 0;
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatMoney = (n) => {
  const price = isString(n) ? +n : n;
  if (price < 0) return 0;
  return price?.toLocaleString("vi-VN", {
    style: "currency",
    currency: "VND",
  });
};

export const formatCurrency = (n) => {
  if (n == null || isNaN(n) || n === "") return "0 VND"; 
  const price = typeof n === "string" ? Number(n) : n;
  if (isNaN(price) || price < 0) return "0 VND"; 
  return price.toLocaleString("vi-VN", {
    style: "currency",
    currency: "VND",
    minimumFractionDigits: price % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  });
};


export const isImageURL = (url) => {
  return url?.match(/\.(jpeg|jpg|gif|png)$/) != null;
};
export const isPdfURL = (url) => {
  return url?.match(/\.(pdf)$/) != null;
};
export const isOfficeURL = (url) => {
  // '.ppt' '.pptx' '.doc', '.docx', '.xls', '.xlsx'
  return url?.match(/\.(ppt|pptx|doc|docx|xls|xlsx)$/) != null;
};
export const isMediaURL = (url) => {
  return url?.match(/\.(mp4|mp3|mov|avi|wmv|webm|flv)$/) != null;
};

export const formatterNumber = (val) => {
  if (!val) return 0;
  const formater = new Intl.NumberFormat("vi-VN");
  return formater.format(val);
};
export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    // eslint-disable-next-line no-useless-escape
    val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
  ).toFixed(5);
};
export const removeVietnameseTones = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  // eslint-disable-next-line no-useless-escape
  str = str.replace(
    // eslint-disable-next-line no-useless-escape
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  return str;
};
