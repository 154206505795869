import { Button, Card, Form, Input, Popconfirm, Spin, Switch, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import Utils from "utils";
import { PermitValue, permitKey } from "constants/index";
import MediTable from "components/Custom";
import {
  deleteKhachHangNCCChiTiet,
  getKhachHangNCCChiTiet,
  upsertKhachHangNCCChiTiet,
} from "store/slices/khachhangSlice";
import { isEmpty } from "lodash";

export const initHoSo = {
  action: "initial",
  isRequired: true,
};
const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        dataIndex === "trangThai" ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        ) : (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập ô bắt buộc",
              },
            ]}
          >
            <Input placeholder={title} />
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  );
};

const ThongTinThanhToan = ({ congTyId }) => {
  const dispatch = useDispatch();
  const { loading, KhachHangNccChiTietList } = useSelector(
    (state) => state.khachhang
  );
  const { permitList } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isEditing = (record) =>
    record.action === "initial"
      ? record.action === editingKey
      : record.id === editingKey;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const edit = (record) => {
    form.setFieldsValue({
      soTaiKhoan: null,
      nganHang: null,
      nguoiNhan: null,
      trangThai: true,
      ...record,
    });
    setEditingKey(record.action === "initial" ? record.action : record.id);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = KhachHangNccChiTietList.slice(0);
      const index = newData.findIndex((item) =>
        item.id ? item.id === key : item.action === key
      );
      const item = newData[index];
      const payload = {
        id: item?.id || null,
        congTyId: congTyId,
        ...row,
        onSuccess: () => {
          dispatch(getKhachHangNCCChiTiet(congTyId));
          setEditingKey("");
        },
      };

      dispatch(upsertKhachHangNCCChiTiet(payload));
    } catch (error) {}
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allowView = (value) =>
    Utils.checkPermitValue(value, permitList, permitKey.crm_thietlapdmksk);

  useEffect(() => {
    if (isEmpty(congTyId)) return;
    dispatch(getKhachHangNCCChiTiet(congTyId)); //
  }, [congTyId, dispatch]);

  const tableColumns = useMemo(
    () => [
      {
        title: "STT",
        align: "center",
        render: (_, __, index) => index + 1,
      },
      {
        title: "Số tài khoản",
        dataIndex: "soTaiKhoan",
        editable: true,
        width: 120,
      },
      {
        title: "Ngân hàng",
        dataIndex: "nganHang",
        editable: true,
        width: 200,
      },
      {
        title: "Người nhận",
        dataIndex: "nguoiNhan",
        editable: true,
        width: 130,
      },
      {
        title: "Ngày tạo",
        dataIndex: "createdAt",
        render: (value) => value && Utils.formatDate(value),
        width: 140,
      },
      {
        title: "Trạng thái",
        align: "center",
        dataIndex: "trangThai",
        editable: true,
        width: 90,
        render: (value, record) =>
          record.id && (
            <Tag color={value ? "green" : "red"}>
              {value ? "Đang sử dụng" : "Ngừng sử dụng"}
            </Tag>
          ),
      },
      {
        fixed: "right",
        align: "center",
        render: (_, record, index) => {
          const editable = isEditing(record);
          return editable ? (
            <>
              <Button
                onClick={() => save(record?.id || "initial")}
                className="mr-2"
                icon={<SaveOutlined />}
                shape="circle"
              />
              <Button
                onClick={() => setEditingKey("")}
                className="mr-2"
                icon={<CloseOutlined />}
                shape="circle"
              />
            </>
          ) : (
            <>
              <Button
                title={
                  record.action === "initial"
                    ? "Thêm thông tin"
                    : "Sửa thông tin"
                }
                disabled={
                  record.action === "initial"
                    ? !allowView(PermitValue.them)
                    : !allowView(PermitValue.sua)
                }
                onClick={() => edit(record)}
                className="mr-2"
                icon={
                  record.action === "initial" ? (
                    <PlusOutlined />
                  ) : (
                    <EditOutlined />
                  )
                }
                shape="circle"
              />
              {record.id && (
                <>
                  <Popconfirm
                    title="Bạn có muốn xóa không?"
                    placement="topLeft"
                    onConfirm={() => {
                      dispatch(
                        deleteKhachHangNCCChiTiet({
                          id: record.id,
                          onSuccess: () => {
                            dispatch(getKhachHangNCCChiTiet(congTyId));
                          },
                        })
                      );
                    }}
                  >
                    <Button
                      title={"Xóa thông tin"}
                      disabled={!allowView(PermitValue.xoa)}
                      className="mr-2"
                      icon={<DeleteOutlined type="primary" />}
                      shape="circle"
                    />
                  </Popconfirm>
                </>
              )}
            </>
          );
        },
      },
    ],
    [allowView, congTyId, dispatch, edit, isEditing, save]
  );

  const mergedColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card>
      <h3>Thông tin thanh toán của khách hàng</h3>
      <Spin tip="Đang tải..." spinning={loading}>
        <Form form={form} component={false}>
          <MediTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            tableColumns={mergedColumns}
            dataSource={[{ action: "initial" }].concat(KhachHangNccChiTietList)}
            scroll={{ x: "max-content" }}
            rowKey={(item) => item?.id}
          />
        </Form>
      </Spin>
    </Card>
  );
};

export default ThongTinThanhToan;
