import axiosInstance from "auth/FetchInterceptor";
import { TAI_SAN_API } from "constants/ApiConstant";

const ErpImportTransactionService = {};

ErpImportTransactionService.getAllByType = function (type) {
  return axiosInstance.get(
    `/api/ErpImportTransaction/get-by-type?type=${type}`,
    {
      baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
    }
  );
};

ErpImportTransactionService.import = function (formData) {
  return axiosInstance.post("/api/ErpImportTransaction/import", formData, {
    baseURL: process.env.REACT_APP_COMMON_ENDPOINT_URL,
  });
};

ErpImportTransactionService.getImportTaiSanDetail = function (transId) {
  return axiosInstance.get(
    `${TAI_SAN_API}/get-import-details?transId=${transId}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

export default ErpImportTransactionService;
