import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { AUTHENTICATED_ENTRY } from "configs/AppConfig";

export const PUBLICROUTES = [
  "/crm/cdha",
  "/crm/tracuu_cls",
  "/crm/tracuu_cls/ketquaxetnghiem",
  "/medic-info",
  "/y-te-medic-info",
  "/customer/idea-initiative/success",
  "/customer/idea-initiative/form",
  "/customer/ghi-nhan-yeu-cau",
  "/customer/ghi-nhan-yeu-cau/detail",
  "/customer/ghi-nhan-yeu-cau/success",
  "/customer/dang-ky-dich-vu-tai-nha/success",
  "/customer/dang-ky-dich-vu-tai-nha",
  "/documentviewer",
  "/app/asset/view",
];

const PublicRoute = () => {
  const { pathname } = useLocation();
  const { token, redirect } = useSelector((state) => state.auth);
  const isPublicRoute = PUBLICROUTES.find((item) => pathname.includes(item));
  console.log(pathname, isPublicRoute);
  return token && !isPublicRoute ? <Navigate to={redirect} /> : <Outlet />;
};

export default PublicRoute;
