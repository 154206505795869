import axiosInstance from "auth/FetchInterceptor";
import {
    Bao_Mat_API
} from "constants/ApiConstant";

const BaoMatService = {};


BaoMatService.GetAllTaiSanCoTheBaoMatByid = function (data) {
  return axiosInstance.get(`${Bao_Mat_API}/get-tai-san-co-the-bao-mat?id=${data}`,  {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

BaoMatService.CreateBaoMat = function (data) {
  return axiosInstance.post(`${Bao_Mat_API}/create`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

BaoMatService.GetThongTinPhieuBaoMat = function (data) {
  return axiosInstance.get(`${Bao_Mat_API}/phieu?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

BaoMatService.GetDanhSachPhieuBaoMat = function (data) {
  return axiosInstance.post(`${Bao_Mat_API}/search`,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

BaoMatService.UpdateChiTietBaoMat = function (data) {
  return axiosInstance.put(`${Bao_Mat_API}/chitiet`,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

BaoMatService.DeletePhieuBaoMat = function (id) {
  return axiosInstance.delete(`${Bao_Mat_API}?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

BaoMatService.DeleteChiTietPhieuBaoMat = function (id) {
  return axiosInstance.delete(`${Bao_Mat_API}/chi-tiet?id=${id}`,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

BaoMatService.GetDanhSachTaiSanDaMat = function (data) {
  return axiosInstance.post(`${Bao_Mat_API}/search/damat`,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

BaoMatService.GetDanhSachPhieuHuyTaiSan = function (data) {
  return axiosInstance.post(`${Bao_Mat_API}/search/damat`,data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};





export default BaoMatService;
