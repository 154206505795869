import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import TaiSanService from "services/Asset/List/TaiSanService";

export const getCapPhat = createAsyncThunk(
  "capPhatTaiSan/getCapPhat",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetCapPhat(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createCapPhatTaiSan = createAsyncThunk(
  "capPhatTaiSan/createCapPhatTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.CreateCapPhat(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);
export const updateCapPhatTaiSan = createAsyncThunk(
  "capPhatTaiSan/updateCapPhatTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.UpdateCapPhat(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);
export const deleteCapPhatById = createAsyncThunk(
  "capPhatTaiSan/deleteCapPhatById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.DeleteCapPhat(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const deleteChiTietCapPhatById = createAsyncThunk(
  "capPhatTaiSan/deleteChiTietCapPhatById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.DeleteChiTietCapPhat(id);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const getCapPhatById = createAsyncThunk(
  "capPhatTaiSan/getCapPhatById",
  async (data, { rejectWithValue }) => {
    try {
      const { id, onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetCapPhatById(id);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const updateCapPhatCTList = createAsyncThunk(
  "capPhatTaiSan/updateCapPhatCTList",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      console.log("Lưu");

      const response = await TaiSanService.updateCapPhatCTList(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

const initialState = {
  loading: false,
  capPhatTaiSanList: [],
  capPhatTaiSanDetail: null,
  capPhatTaiSanChiTietList: [],
};

const capPhatTaiSanSlice = createSlice({
  name: "capPhatTaiSan",
  initialState,
  reducers: {
    updateCapPhatTaiSanChiTietList: (state, action) => {
      state.capPhatTaiSanChiTietList = action.payload;
    },
    resetCapPhatTaiSanDetail: (state, action) => {
      state.capPhatTaiSanDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCapPhat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCapPhat.fulfilled, (state, action) => {
        state.loading = false;
        state.capPhatTaiSanList = action.payload;
      })
      .addCase(getCapPhat.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createCapPhatTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCapPhatTaiSan.fulfilled, (state,action) => {
        state.loading = false;
        state.capPhatTaiSanDetail= action.payload;
      })
      .addCase(createCapPhatTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCapPhatTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCapPhatTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCapPhatTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCapPhatById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCapPhatById.fulfilled, (state, action) => {
        state.loading = false;
        state.capPhatTaiSanDetail = action.payload;
      })
      .addCase(getCapPhatById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCapPhatCTList.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCapPhatCTList.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCapPhatCTList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteCapPhatById.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCapPhatById.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteCapPhatById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteChiTietCapPhatById.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChiTietCapPhatById.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteChiTietCapPhatById.rejected, (state) => {
        state.loading = false;
      })
      ;
  },
});
export const { updateCapPhatTaiSanChiTietList, resetCapPhatTaiSanDetail } =
  capPhatTaiSanSlice.actions;

export default capPhatTaiSanSlice.reducer;
