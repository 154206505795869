import axiosInstance from "auth/FetchInterceptor";
const { PREFIX } = require("constants/ApiConstant");

const CATEGORY_API = `${PREFIX}/Category`;
const CategoryService = {};

CategoryService.getDmChung = function () {
  return axiosInstance.get(`${CATEGORY_API}/get-all-dmchung`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

CategoryService.getAssetCate = function () {
  return axiosInstance.get(`${CATEGORY_API}/get-all-asset`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

CategoryService.getComponentAssetCate = function () {
  return axiosInstance.get(`${CATEGORY_API}/get-all-component-asset`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

CategoryService.searchDmChung = function (data) {
  return axiosInstance.post(`${CATEGORY_API}/search-dm-chung`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

CategoryService.upSertDmChung = function (data) {
  return axiosInstance.post(`${CATEGORY_API}/upsert-dm-chung`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

export default CategoryService;
