import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ErpImportTransactionService from "services/common/ErpImportTransactionService";

export const getAllImportTransactionByType = createAsyncThunk(
  "erpImportTransaction/getAllImportTransactionByType",
  async (type, { rejectWithValue }) => {
    try {
      const response = await ErpImportTransactionService.getAllByType(type);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const CommonImportTransaction = createAsyncThunk(
  "erpImportTransaction/CommonImportTransaction",
  async (data, { rejectWithValue }) => {
    const { onSuccess, formData } = data;
    try {
      const response = await ErpImportTransactionService.import(formData);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getImportTaiSanByTransId = createAsyncThunk(
  "taiSan/getImportTaiSanByTransId",
  async (transId, { rejectWithValue }) => {
    try {
      const response = await ErpImportTransactionService.getImportTaiSanDetail(
        transId
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  history: [],
  details: [],
};

const erpImportTransactionSlice = createSlice({
  name: "erpImportTransaction",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllImportTransactionByType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllImportTransactionByType.fulfilled,
      (state, action) => {
        state.loading = false;
        state.history = action.payload;
      }
    );
    builder.addCase(getAllImportTransactionByType.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getImportTaiSanByTransId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getImportTaiSanByTransId.fulfilled, (state, action) => {
      state.loading = false;
      state.details = action.payload;
    });
    builder.addCase(getImportTaiSanByTransId.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(CommonImportTransaction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(CommonImportTransaction.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(CommonImportTransaction.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setLoading } = erpImportTransactionSlice.actions;

export default erpImportTransactionSlice.reducer;
