import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import DeNghiThanhLyService from "services/Asset/DeNghiThanhLy/DeNghiThanhLyService";

export const createdeNghiThanhLy = createAsyncThunk(
  "deNghiThanhLy/createdeNghiThanhLy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DeNghiThanhLyService.CreateDeNghiThanhLy(data);
      if (response.data) {
        if (onSuccess) onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getTaiSanCoTheDeNghiThanhLyByTaiSanId = createAsyncThunk(
  "deNghiThanhLy/getTaiSanCoTheDeNghiThanhLyByTaiSanId",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response =
        await DeNghiThanhLyService.GetTaiSanCoTheDeNghiThanhLyByTaiSanID(id);
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getPhieuDeNghiThanhLyById = createAsyncThunk(
  "deNghiThanhLy/getPhieuDeNghiTyhanhLyById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await DeNghiThanhLyService.GetPhieuDeNghiThanhLyById(id);
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updatePhieuDeNghiThanhLy = createAsyncThunk(
  "deNghiThanhLy/updatePhieuDeNghiThanhLy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await DeNghiThanhLyService.UpdatePhieuDeNghiThanhLy(
        data
      );
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deletePhieuDeNghiThanhLy = createAsyncThunk(
  "deNghiThanhLy/deletePhieuDeNghiThanhLy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await DeNghiThanhLyService.DeletePhieuDeNgHiThanhLyById(
        id
      );
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteChiTietDeNghiThanhLy = createAsyncThunk(
  "deNghiThanhLy/deleteChiTietDeNghiThanhLy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response =
        await DeNghiThanhLyService.DeleteChiTietDeNgHiThanhLyById(id);
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const searchDanhSachPhieuDeNghiThanhLy = createAsyncThunk(
  "deNghiThanhLy/searchDanhSachPhieuDeNghiThanhLy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await DeNghiThanhLyService.GetDanhSachPhieuDeNghiThanhLy(
        data
      );
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getDanhSachTaiSanCanThanhLy = createAsyncThunk(
  "deNghiThanhLy/getDanhSachTaiSanCanThanhLy",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DeNghiThanhLyService.GetDanhSachTaiSanCanThanhLy(
        data
      );
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const thanhLy = createAsyncThunk(
  "deNghiThanhLy/thanhLy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await DeNghiThanhLyService.ThanhLy(data);
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  danhsachtaisancothedenghithanhly: [],
  denghithanhlydetail: {},
  danhsachphieudenghithanhly: [],
  danhsachtaisancanthanhly: [],
  danhsachtaisandathanhly: [],
};

const DeNghiThanhLySlice = createSlice({
  name: "deNghiThanhLy",
  initialState,
  reducers: {
    resetdanhsachtaisancothedenghithanhly: (state) => {
      state.danhsachtaisancothedenghithanhly = [];
    },
    resetdenghithanhlydetail: (state) => {
      state.denghithanhlydetail = {};
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createdeNghiThanhLy.pending, (state) => {
        state.loading = true;
      })
      .addCase(createdeNghiThanhLy.fulfilled, (state, action) => {
        state.loading = false;
        state.denghithanhlydetail = action.payload;
      })
      .addCase(createdeNghiThanhLy.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTaiSanCoTheDeNghiThanhLyByTaiSanId.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getTaiSanCoTheDeNghiThanhLyByTaiSanId.fulfilled,
        (state, action) => {
          state.loading = false;
          state.danhsachtaisancothedenghithanhly = action.payload;
        }
      )
      .addCase(getTaiSanCoTheDeNghiThanhLyByTaiSanId.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getPhieuDeNghiThanhLyById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPhieuDeNghiThanhLyById.fulfilled, (state, action) => {
        state.loading = false;
        state.denghithanhlydetail = action.payload;
      })
      .addCase(getPhieuDeNghiThanhLyById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updatePhieuDeNghiThanhLy.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePhieuDeNghiThanhLy.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePhieuDeNghiThanhLy.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deletePhieuDeNghiThanhLy.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhieuDeNghiThanhLy.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deletePhieuDeNghiThanhLy.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteChiTietDeNghiThanhLy.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChiTietDeNghiThanhLy.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteChiTietDeNghiThanhLy.rejected, (state) => {
        state.loading = false;
      })

      .addCase(searchDanhSachPhieuDeNghiThanhLy.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchDanhSachPhieuDeNghiThanhLy.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachphieudenghithanhly = action.payload;
      })
      .addCase(searchDanhSachPhieuDeNghiThanhLy.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDanhSachTaiSanCanThanhLy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachTaiSanCanThanhLy.fulfilled, (state, action) => {
        state.loading = false;
        if (action.meta.arg?.daThanhLy)
          state.danhsachtaisandathanhly = action.payload;
        state.danhsachtaisancanthanhly = action.payload;
      })
      .addCase(getDanhSachTaiSanCanThanhLy.rejected, (state) => {
        state.loading = false;
      })

      .addCase(thanhLy.pending, (state) => {
        state.loading = true;
      })
      .addCase(thanhLy.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(thanhLy.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const {
  resetdanhsachtaisancothedenghithanhly,
  resetdenghithanhlydetail,
} = DeNghiThanhLySlice.actions;

export default DeNghiThanhLySlice.reducer;
