import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ViTriTaiSanService from "services/Asset/Settings/ViTriTaiSanService";
import { cloneDeep } from "lodash";

export const GetTreeViTriTaiSan = createAsyncThunk(
  "viTriTaiSan/GetTreeViTriTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ViTriTaiSanService.GetTreeViTriTaiSan(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDanhMucViTriTaiSan = createAsyncThunk(
  "viTriTaiSan/getDanhMucViTriTaiSan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ViTriTaiSanService.DanhMucViTriTaiSan();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetListPhongBan = createAsyncThunk(
  "viTriTaiSan/GetListPhongBan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ViTriTaiSanService.GetListPhongBan();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetViTriTaiSan = createAsyncThunk(
  "viTriTaiSan/GetViTriTaiSan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ViTriTaiSanService.GetViTriTaiSan();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpdateViTriTaiSan = createAsyncThunk(
  "viTriTaiSan/UpdateViTriTaiSan",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ViTriTaiSanService.UpdateViTriTaiSan(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const CreateViTriTaiSan = createAsyncThunk(
  "viTriTaiSan/CreateViTriTaiSan",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ViTriTaiSanService.CreateViTriTaiSan(data);
      if (onSuccess) onSuccess(response.data);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const DeleteViTriTaiSan = createAsyncThunk(
  "viTriTaiSan/DeleteViTriTaiSan",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await ViTriTaiSanService.DeleteViTriTaiSan(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const GetViTriTaiSanById = createAsyncThunk(
  "viTriTaiSan/GetViTriTaiSanById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await ViTriTaiSanService.GetViTriTaiSanById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  viTriTaiSanTree: [],
  viTriTaiSan: [],
  viTriTaiSanDetail: {
    loading: false,
    data: null,
  },
  DanhMucViTriTaiSan: [],
  DanhMucListPhongBan: [],
};

const viTriTaiSanSlice = createSlice({
  name: "viTriTaiSan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //GetListPhongBan
      .addCase(GetListPhongBan.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetListPhongBan.fulfilled, (state, action) => {
        state.loading = false;
        state.DanhMucListPhongBan = action.payload;
      })
      .addCase(GetListPhongBan.rejected, (state) => {
        state.loading = false;
      })
      //getDanhMucViTriTaiSan
      .addCase(getDanhMucViTriTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhMucViTriTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.DanhMucViTriTaiSan = action.payload;
      })
      .addCase(getDanhMucViTriTaiSan.rejected, (state) => {
        state.loading = false;
      })
      //GetViTriTaiSan
      .addCase(GetViTriTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetViTriTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.viTriTaiSan = action.payload;
      })
      .addCase(GetViTriTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetTreeViTriTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTreeViTriTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.viTriTaiSanTree = action.payload;
      })
      .addCase(GetTreeViTriTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateViTriTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateViTriTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(UpdateViTriTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(CreateViTriTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateViTriTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(CreateViTriTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(DeleteViTriTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteViTriTaiSan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteViTriTaiSan.rejected, (state) => {
        state.loading = false;
      })

      .addCase(GetViTriTaiSanById.pending, (state) => {
        state.viTriTaiSanDetail = { ...state.dinhKhoanDetail, loading: true };
      })
      .addCase(GetViTriTaiSanById.fulfilled, (state, action) => {
        state.viTriTaiSanDetail = { data: action.payload, loading: false };
      })
      .addCase(GetViTriTaiSanById.rejected, (state) => {
        state.viTriTaiSanDetail = {
          ...state.dinhKhoanDetail,
          loading: false,
        };
      });
  },
});

export default viTriTaiSanSlice.reducer;
