import axiosInstance from "auth/FetchInterceptor";
import { TONGHOPDEXUATMUASAM_API } from "constants/ApiConstant";
const TongHopDeXuatMuaSamService = {};

// start dexuatmuasam

TongHopDeXuatMuaSamService.GetAllTongHop = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-all-de-xuat-mua-sam`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
TongHopDeXuatMuaSamService.CapNhatTongHop = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/cap-nhat-de-xuat-mua-sam`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.GetTongHop = function (data) {
  return axiosInstance.get(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-de-xuat-mua-sam?id=${data}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.GetTongHopChiTiet = function (id) {
  return axiosInstance.get(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-de-xuat-mua-sam-chi-tiet?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
TongHopDeXuatMuaSamService.CreateDeXuatChiTietDuyet = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/de-xuat-duyet-chi-tiet-create`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
TongHopDeXuatMuaSamService.UpdateDeXuatChiTietDuyet = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/de-xuat-duyet-chi-tiet-update`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.GetDeXuatChiTietDuyet = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-de-xuat-chi-tiet-duyet`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.TaoBanSaoDeXuatChiTietDuyet = function (id) {
  return axiosInstance.get(
    `${TONGHOPDEXUATMUASAM_API}/sale/tao-ban-sao-de-xuat-chi-tiet-duyet?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.duyetDeXuat = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/duyet-de-xuat-mua-sam`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.HoanHoiDeXuat = function (id) {
  return axiosInstance.get(
    `${TONGHOPDEXUATMUASAM_API}/sale/hoan-hoi-de-xuat-mua-sam?id=${id}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.TongHopTuan = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/tong-hop-de-xuat-theo-tuan`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.GetAllTongHopTuan = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-all-tong-hop-de-xuat-theo-tuan`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.GetTongHopTuan = function (data) {
  return axiosInstance.get(
    `${TONGHOPDEXUATMUASAM_API}/sale/get-tong-hop-de-xuat-tuan?id=${data}`,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.CapNhatTongHopTuan = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/cap-nhat-tong-hop-de-xuat-theo-tuan`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.deleteTongHopDeXuatTuan = function (id) {
  return axiosInstance.delete(
    `${TONGHOPDEXUATMUASAM_API}/sale/delete-tong-hop-de-xuat-theo-tuan?id=${id}`,
    {
      baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.exportTongHopDeXuatTuan = function (id) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/export-tong-hop-de-xuat-tuan?id=${id}`,
    null,
    {
      baseURL: process.env.REACT_APP_SALE_ENDPOINT_URL,
    }
  );
};

TongHopDeXuatMuaSamService.TaoKeHoachMuaHang = function (data) {
  return axiosInstance.post(
    `${TONGHOPDEXUATMUASAM_API}/sale/tao-ke-hoach-mua-hang-tu-tong-hop-de-xuat`,
    data,
    {
      baseURL: process.env.REACT_APP_PHARMA_ENDPOINT_URL,
    }
  );
};
// end dexuatmuasam

export default TongHopDeXuatMuaSamService;
