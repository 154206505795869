import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import TaiSanService from "services/Asset/List/TaiSanService";

export const getThongTinThuHoiByTaiSanId = createAsyncThunk(
  "thuHoiTaiSan/getThongTinThuHoiByTaiSanId",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetThongTinThuHoiByTaiSanId(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getThuHoi = createAsyncThunk(
  "thuHoiTaiSan/getThuHoi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetThuHoi(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createThuHoiTaiSan = createAsyncThunk(
  "thuHoiTaiSan/createThuHoiTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.CreateThuHoi(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);
export const updateThuHoiTaiSan = createAsyncThunk(
  "thuHoiTaiSan/updateThuHoiTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.UpdateThuHoi(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const getTaiSanCoTheThuHoiByID = createAsyncThunk(
  "thuHoiTaiSan/getTaiSanCoTheThuHoiByID",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetTaiSanCoTheThuHoiById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getTaiSanCoTheThuHoi = createAsyncThunk(
  "thuHoiTaiSan/getTaiSanCoTheThuHoi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetTaiSanCoTheThuHoi(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getThuHoiById = createAsyncThunk(
  "thuHoiTaiSan/getThuHoiById",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetThuHoiByid(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getTaiSanByIdFromThuHoi = createAsyncThunk(
  "taiSan/getTaiSanByIdFromThuHoi",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await TaiSanService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);



export const deletePhieuThuHoi = createAsyncThunk(
  "thuHoiTaiSan/deletePhieuThuHoi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.DeletePhieuThuHoi(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);


export const deleteChiTietPhieuThuHoi = createAsyncThunk(
  "thuHoiTaiSan/deleteChiTietPhieuThuHoi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.DeleteChiTietPhieuThuHoi(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);


const initialState = {
  loading: false,
  thuHoiTaiSanList: [],
  thuHoiTaiSanDetail: {},
  thuHoiTaiSanChiTietList: [],

};

const thuHoiTaiSanSlice = createSlice({
  name: "thuHoiTaiSan",
  initialState,
  reducers: {
    updateThuHoiTaiSanChiTietList: (state, action) => {
      state.thuHoiTaiSanChiTietList = action.payload;
    },
    resetThuHoiTaiSanDetail: (state,) => {
      state.thuHoiTaiSanDetail = null;
    },
    resetThuHoiTaiSanChiTietList: (state, action) => {
      state.thuHoiTaiSanChiTietList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThuHoi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThuHoi.fulfilled, (state, action) => {
        state.loading = false;
        state.thuHoiTaiSanList = action.payload;
      })
      .addCase(getThuHoi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createThuHoiTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createThuHoiTaiSan.fulfilled, (state,action) => {
        state.loading = false;
        state.thuHoiTaiSanDetail = action.payload

      })
      .addCase(createThuHoiTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateThuHoiTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateThuHoiTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateThuHoiTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTaiSanCoTheThuHoiByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSanCoTheThuHoiByID.fulfilled, (state, action) => {
        state.loading = false;
        state.thuHoiTaiSanChiTietList = action.payload;
      })
      .addCase(getTaiSanCoTheThuHoiByID.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThongTinThuHoiByTaiSanId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThongTinThuHoiByTaiSanId.fulfilled, (state, action) => {
        state.loading = false;
        state.thongTinTHTSList = action.payload;
      })
      .addCase(getThongTinThuHoiByTaiSanId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTaiSanCoTheThuHoi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSanCoTheThuHoi.fulfilled, (state, action) => {
        state.loading = false;
        state.thuHoiTaiSanChiTietList = action.payload;
      })
      .addCase(getTaiSanCoTheThuHoi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThuHoiById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThuHoiById.fulfilled, (state, action) => {
        state.loading = false;
        state.thuHoiTaiSanChiTietList = action.payload.thuHoiTaiSanChiTietList;
        state.thuHoiTaiSanDetail = action.payload;
      })
      .addCase(getThuHoiById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTaiSanByIdFromThuHoi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSanByIdFromThuHoi.fulfilled, (state, action) => {
        state.loading = false;
        state.thuHoiTaiSanDetail = action.payload;
      })
      .addCase(getTaiSanByIdFromThuHoi.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deletePhieuThuHoi.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhieuThuHoi.fulfilled, (state, action) => {
        state.loading = false;

      })
      .addCase(deletePhieuThuHoi.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteChiTietPhieuThuHoi.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChiTietPhieuThuHoi.fulfilled, (state, action) => {
        state.loading = false;

      })
      .addCase(deleteChiTietPhieuThuHoi.rejected, (state) => {
        state.loading = false;
      })
      
      ;
  },
});
export const { updateThuHoiTaiSanChiTietList, resetThuHoiTaiSanDetail , resetThuHoiTaiSanChiTietList} =
  thuHoiTaiSanSlice.actions;

export default thuHoiTaiSanSlice.reducer;
