import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import AssetDieuChuyenService from "services/Asset/AssetDieuChuyenService";

export const GetAllAssetDieuChuyen = createAsyncThunk(
  "AssetDieuChuyen/GetAllAssetDieuChuyen",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AssetDieuChuyenService.GetAllAssetDieuChuyen(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetAssetDieuChuyen = createAsyncThunk(
  "AssetDieuChuyen/GetAssetDieuChuyen",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AssetDieuChuyenService.GetAssetDieuChuyen(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertAssetDieuChuyen = createAsyncThunk(
  "AssetDieuChuyen/UpsertAssetDieuChuyen",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await AssetDieuChuyenService.UpsertAssetDieuChuyen(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteAssetDieuChuyen = createAsyncThunk(
  "AssetDieuChuyen/DeleteAssetDieuChuyen",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await AssetDieuChuyenService.DeleteAssetDieuChuyen(
        payload.id
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetAssetDieuChuyenChiTiet = createAsyncThunk(
  "AssetDieuChuyen/GetAssetDieuChuyenChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AssetDieuChuyenService.GetAssetDieuChuyenChiTiet(
        data
      );
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertAssetDieuChuyenChiTiet = createAsyncThunk(
  "AssetDieuChuyen/UpsertAssetDieuChuyenChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await AssetDieuChuyenService.UpsertAssetDieuChuyenChiTiet(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteAssetDieuChuyenChiTiet = createAsyncThunk(
  "AssetDieuChuyen/DeleteAssetDieuChuyenChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await AssetDieuChuyenService.DeleteAssetDieuChuyenChiTiet(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetListTaiSanByLoaiDC = createAsyncThunk(
  "AssetDieuChuyen/GetListTaiSanByLoaiDC",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AssetDieuChuyenService.GetListTaiSanByLoaiDC(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetAllTaiSan = createAsyncThunk(
  "AssetDieuChuyen/GetAllTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AssetDieuChuyenService.GetAllTaiSan();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  AssetDieuChuyenList: [],
  AssetDieuChuyenDetail: {},
  AssetDieuChuyenChiTietList: [],
  TaiSanByLoaiDCList: [],
  AllTaiSanList: [],
};

const AssetDieuChuyenTienSlice = createSlice({
  name: "AssetDieuChuyen",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetAllTaiSan
      .addCase(GetAllTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.AllTaiSanList = action.payload;
      })
      .addCase(GetAllTaiSan.rejected, (state) => {
        state.loading = false;
      })
      //GetListTaiSanByLoaiDC
      .addCase(GetListTaiSanByLoaiDC.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetListTaiSanByLoaiDC.fulfilled, (state, action) => {
        state.loading = false;
        state.TaiSanByLoaiDCList = action.payload;
      })
      .addCase(GetListTaiSanByLoaiDC.rejected, (state) => {
        state.loading = false;
      })
      //GetAssetDieuChuyenChiTiet
      .addCase(GetAssetDieuChuyenChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAssetDieuChuyenChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.AssetDieuChuyenChiTietList = action.payload;
      })
      .addCase(GetAssetDieuChuyenChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetAssetDieuChuyen
      .addCase(GetAssetDieuChuyen.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAssetDieuChuyen.fulfilled, (state, action) => {
        state.loading = false;
        state.AssetDieuChuyenDetail = action.payload;
      })
      .addCase(GetAssetDieuChuyen.rejected, (state) => {
        state.loading = false;
      })
      //GetAllAssetDieuChuyen
      .addCase(GetAllAssetDieuChuyen.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllAssetDieuChuyen.fulfilled, (state, action) => {
        state.loading = false;
        state.AssetDieuChuyenList = action.payload;
      })
      .addCase(GetAllAssetDieuChuyen.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = AssetDieuChuyenTienSlice.actions;

export default AssetDieuChuyenTienSlice.reducer;
