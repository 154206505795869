import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CrmVoteService from "services/crm/CrmVoteService";

export const SearchVoteManagement = createAsyncThunk(
  "crmVote/SearchVoteManagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CrmVoteService.search(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetVoteById = createAsyncThunk(
  "crmVote/GetVoteById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CrmVoteService.getById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpSertVote = createAsyncThunk(
  "crmVote/UpSertVote",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, ...payload } = data;
      const response = await CrmVoteService.upSert(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  detail: null,
  list: [],
};

export const crmVoteSlide = createSlice({
  name: "crmVote",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchVoteManagement.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchVoteManagement.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(SearchVoteManagement.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetVoteById.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetVoteById.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = action.payload;
      })
      .addCase(GetVoteById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(UpSertVote.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpSertVote.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpSertVote.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = crmVoteSlide.actions;

export default crmVoteSlide.reducer;
