import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import SuaChuaService from "services/Asset/SuaChua/SuaChuaService";



export const createBaoHong= createAsyncThunk(
  "suaChua/createBaoHong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SuaChuaService.CreateBaoHong(data);
      if (onSuccess) onSuccess(response);
      // console.log(response,"res")
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createDanhHong= createAsyncThunk(
  "suaChua/createDanhHong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await SuaChuaService.CreateDanhHong(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createCanSua= createAsyncThunk(
  "suaChua/createCanSua",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SuaChuaService.CreateCanSua(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const searchCanSua= createAsyncThunk(
    "suaChua/searchCanSua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.SeachCanSua(data);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  export const searchDangSua= createAsyncThunk(
    "suaChua/searchDangSua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.SeachDangSua(data);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const seachTaiSan= createAsyncThunk(
    "suaChua/seachTaiSan",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.SeachTaiSan(data);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );
  
  export const updateChiTietSuaChua= createAsyncThunk(
    "suaChua/updateChiTietSuaChua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.UpdateChiTietSuaChua(data);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  export const updateChiTietBaoHong= createAsyncThunk(
    "suaChua/updateChiTietBaoHong",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.UpdateChiTietBaoHong(data);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const getTaiSanCoTheBaoHong= createAsyncThunk(
    "suaChua/getTaiSanCoTheBaoHong",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess, arr } = data;
        const response = await SuaChuaService.GetTaiSanCoTheBaoHong(arr);
        onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  
 
  export const getDanhSachBaoHong= createAsyncThunk(
    "suaChua/getDanhSachBaoHong",
    async (data, { rejectWithValue }) => {
      try {
        const response = await SuaChuaService.GetDanhSachBaoHong(data);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );
  
  export const getThongTinPhieuBaoHong= createAsyncThunk(
    "suaChua/getThongTinPhieuBaoHong",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.GetThongTinPhieuBaoHong(payload.id);
        // console.log(response,"res")
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );
  
  export const updateHoanThanhSuaChua= createAsyncThunk(
    "suaChua/updateHoanThanhSuaChua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.UpdateHoanThanhSuaChua(payload);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );
  
  export const getAllHangMuc= createAsyncThunk(
    "suaChua/getAllHangMuc",
    async (data, { rejectWithValue }) => {
      try {

        const response = await SuaChuaService.GetAllHangMuc(data);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );
  export const lichSuSuaChua= createAsyncThunk(
    "suaChua/lichSuSuaChua",
    async (data, { rejectWithValue }) => {
      try {

        const response = await SuaChuaService.LichSuSuaChua(data);
        // console.log(response,"res")
        return response?.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const getDanhSachphieuSuaChua= createAsyncThunk(
    "suaChua/getDanhSachphieuSuaChua",
    async (data, { rejectWithValue }) => {
      try {

        const response = await SuaChuaService.GetDanhSachPhieuSuaChua(data);
        return response?.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  export const getChiTietPhieuSuaChua= createAsyncThunk(
    "suaChua/getChiTietPhieuSuaChua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess,id } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.GetChiTietPhieuSuaChua(id);
        if (onSuccess) onSuccess(response);
        return response?.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  export const deletePhieuBaoHong= createAsyncThunk(
    "suaChua/deletePhieuBaoHong",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess,id } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.DeletePhieuBaoHong(id);
        if (onSuccess) onSuccess(response);
        return response?.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const deleteChiTietPhieuBaoHong= createAsyncThunk(
    "suaChua/deleteChiTietPhieuBaoHong",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess,id } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.DeleteChiTietPhieuBaoHong(id);
        if (onSuccess) onSuccess(response);
        return response?.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const deletePhieuBaoSuaChua= createAsyncThunk(
    "suaChua/deletePhieuBaoSuaChua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess,id } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.DeletePhieuBaoSuaChua(id);
        if (onSuccess) onSuccess(response);
        return response?.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const deleteChiTietBaoSuaChua= createAsyncThunk(
    "suaChua/deleteChiTietBaoSuaChua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess,id } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.DeleteChiTietBaoSuaChua(id);
        if (onSuccess) onSuccess(response);
        return response?.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

 

  
  

const initialState = {
  loading: false,
  dsphieubaohong:[],
  danhsachtaisancansua:[],
  danhsachtaisandangsua:[],
  danhsachtaisancothebaohong: [],
  danhsachtaisanbaohong :[],
  baohongdetail :{},
  danhsachphieubaohong :[],
  danhsachhangmuc:[],
  lichsusuachua:[],
  danhsachphieusuachua:[],
  suaChuaDeTail:{},
  chitiettaisancansua:[]

  
};

const suaChuaSlice = createSlice({
  name: "suaChua",
  initialState,
  reducers: {
    resetchitiettaisancansua:(state)=>{
      state.chitiettaisancansua = []
    },
    resetbaohongdetail:(state)=>{
      state.baohongdetail = {}
    },
    resetsuaChuaDeTail:(state)=>{
      state.suaChuaDeTail = {}
    },
    resetdanhsachtaisancothebaohong:(state)=>{
      state.danhsachtaisancothebaohong = []
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBaoHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBaoHong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createBaoHong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(searchCanSua.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchCanSua.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachtaisancansua = action.payload
      })
      .addCase(searchCanSua.rejected, (state) => {
        state.loading = false;
      })

      .addCase(seachTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(seachTaiSan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(seachTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createCanSua.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCanSua.fulfilled, (state, action) => {
        state.loading = false;

      })
      .addCase(createCanSua.rejected, (state) => {
        state.loading = false;
      })
      .addCase(searchDangSua.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchDangSua.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachtaisandangsua = action.payload
      })
      .addCase(searchDangSua.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateChiTietSuaChua.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateChiTietSuaChua.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateChiTietSuaChua.rejected, (state) => {
        state.loading = false;
      })
      
      .addCase(updateChiTietBaoHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateChiTietBaoHong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateChiTietBaoHong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTaiSanCoTheBaoHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSanCoTheBaoHong.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachtaisancothebaohong = action.payload
      })
      .addCase(getTaiSanCoTheBaoHong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getDanhSachBaoHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachBaoHong.fulfilled, (state, action) => {
        state.loading = false;
        state.dsphieubaohong = action.payload;

        // state.danhsachtaisanbaohong = action.payload.chiTiet
        // state.danhsachphieubaohong = action.payload.phieus
      })
      .addCase(getDanhSachBaoHong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThongTinPhieuBaoHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThongTinPhieuBaoHong.fulfilled, (state, action) => {
        state.loading = false;
        state.baohongdetail = action.payload
      })
      .addCase(getThongTinPhieuBaoHong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateHoanThanhSuaChua.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHoanThanhSuaChua.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateHoanThanhSuaChua.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllHangMuc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllHangMuc.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachhangmuc = action.payload
      })
      .addCase(getAllHangMuc.rejected, (state) => {
        state.loading = false;
      })
      .addCase(lichSuSuaChua.pending, (state) => {
        state.loading = true;
      })
      .addCase(lichSuSuaChua.fulfilled, (state, action) => {
        state.loading = false;
        state.lichsusuachua = action.payload
      })
      .addCase(lichSuSuaChua.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getDanhSachphieuSuaChua.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachphieuSuaChua.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachphieusuachua = action.payload
      })
      .addCase(getDanhSachphieuSuaChua.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getChiTietPhieuSuaChua.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChiTietPhieuSuaChua.fulfilled, (state, action) => {
        state.loading = false;
        state.suaChuaDeTail = action.payload
        state.chitiettaisancansua = action.payload?.chiTiet
      })
      .addCase(getChiTietPhieuSuaChua.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deletePhieuBaoHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhieuBaoHong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deletePhieuBaoHong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteChiTietPhieuBaoHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChiTietPhieuBaoHong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteChiTietPhieuBaoHong.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deletePhieuBaoSuaChua.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePhieuBaoSuaChua.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deletePhieuBaoSuaChua.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteChiTietBaoSuaChua.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChiTietBaoSuaChua.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteChiTietBaoSuaChua.rejected, (state) => {
        state.loading = false;
      })

      .addCase(createDanhHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDanhHong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createDanhHong.rejected, (state) => {
        state.loading = false;
      })

      

  },
});
export const {  resetchitiettaisancansua, resetbaohongdetail, resetsuaChuaDeTail, resetdanhsachtaisancothebaohong} = suaChuaSlice.actions;

export default suaChuaSlice.reducer;
