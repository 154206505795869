import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Switch,
  Tabs,
} from "antd";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upsertKhachHangNCC } from "store/slices/khachhangSlice";
import { GetCongTy } from "store/slices/khamSucKhoeDoanSlice";
import Utils from "utils";
import ThongTinThanhToan from "./thongTinThanhToan";

const ModalKhachHangNhaCungCap = ({
  loaiDoiTuong,
  open,
  setOpen,
  onSuccess,
  congTyId,
  reloadData,
}) => {
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.khachhang);
  const { CongTyDetail } = useSelector((state) => state.ksk);
  const dispatch = useDispatch();

  const LoaiDoiTuong = [
    {
      label: "Khách hàng",
      value: 1,
      disabled: loaiDoiTuong === 2,
    },
    {
      label: "Nhà cung cấp",
      value: 2,
      disabled: loaiDoiTuong === 1,
    },
    {
      label: "Khách hàng và Nhà cung cấp",
      value: 4,
      disabled: false,
    },
  ];

  useEffect(() => {
    if (isEmpty(congTyId)) return;
    dispatch(GetCongTy(congTyId));
  }, [congTyId, dispatch]);

  useEffect(() => {
    if (isEmpty(congTyId)) {
      form.setFieldsValue('trangThai', true);
      return;
    }

    form.setFieldsValue(CongTyDetail);
  }, [congTyId, CongTyDetail, form]);

  const onFinish = (values) => {
    console.log("values", values);

    const payload = {
      id: congTyId || null,
      ...values,
      onSuccess: (value) => {
        if (isEmpty(congTyId)) {
          if (onSuccess) onSuccess(value?.data);
          form.resetFields();
        } else {
          reloadData();
          notification.info({
            message: "Lưu danh mục",
            description: "Lưu thành công danh mục",
          });
        }
      },
    };
    dispatch(upsertKhachHangNCC(payload)).then(res=>{
      notification.success({
        message:'Thông báp',
        description: res.description || 'Thêm thành công'
      })
    });
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const items = [
    {
      key: "1",
      label: "Thông tin chung",
      children: (
        <Form form={form} layout="vertical" onFinish={onFinish} className="p-3">
          <Row gutter={16}>
            <Col sm={24} md={24} lg={24}>
              <Form.Item
                name="tenCongTy"
                label="Tên công ty"
                rules={[
                  {
                    required: true,
                    message: "Tên công ty",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <Form.Item name="maSoThue" label="Mã số thuế">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <Form.Item
                name="loaiKhachHang"
                label="Loại đối tượng"
                rules={[
                  {
                    required: true,
                    message: "Loại đối tượng",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  options={LoaiDoiTuong || []}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={24} lg={24}>
              <Form.Item
                name="diaChi"
                label="Địa chỉ"
                rules={[
                  {
                    required: true,
                    message: "Địa chỉ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item name="dienThoai" label="Điện thoại">
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item name="nguoiLienHe" label="Người liên hệ">
                <Input />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item name="tonDuNo" label="Dự nợ">
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => Utils.formatterNumber(value)}
                  parser={(value) => Utils.parserNumber(value)}
                />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item name="tonDuCo" label="Dự có">
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => Utils.formatterNumber(value)}
                  parser={(value) => Utils.parserNumber(value)}
                />
              </Form.Item>
            </Col>

            <Col sm={12} md={12} lg={12}>
              <Form.Item
                name="trangThai"
                label="Trạng thái"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      key: "2",
      label: "Thông tin thanh toán",
      children: <ThongTinThanhToan congTyId={congTyId} />,
    },
  ];

  return (
    <>
      <Modal
        open={open}
        onCancel={() => handleCancel()}
        okText="Lưu"
        onOk={() => handleOk()}
        okButtonProps={{ disabled: loading }}
        maskClosable={false}
        width={1000}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Modal>
    </>
  );
};

export default ModalKhachHangNhaCungCap;
