import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import ChungTuNghiepVuKhacService from "services/Finance/ChungTuNghiepVuKhacService";

export const GetAllChungTuNghiepVuKhac = createAsyncThunk(
  "ChungTuNghiepVuKhac/GetAllChungTuNghiepVuKhac",
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await ChungTuNghiepVuKhacService.GetAllChungTuNghiepVuKhac(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetChungTuNghiepVuKhac = createAsyncThunk(
  "ChungTuNghiepVuKhac/GetChungTuNghiepVuKhac",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ChungTuNghiepVuKhacService.GetChungTuNghiepVuKhac(
        data
      );
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertChungTuNghiepVuKhac = createAsyncThunk(
  "ChungTuNghiepVuKhac/UpsertChungTuNghiepVuKhac",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await ChungTuNghiepVuKhacService.UpsertChungTuNghiepVuKhac(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteChungTuNghiepVuKhac = createAsyncThunk(
  "ChungTuNghiepVuKhac/DeleteChungTuNghiepVuKhac",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await ChungTuNghiepVuKhacService.DeleteChungTuNghiepVuKhac(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetChungTuNghiepVuKhacChiTiet = createAsyncThunk(
  "ChungTuNghiepVuKhac/GetChungTuNghiepVuKhacChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await ChungTuNghiepVuKhacService.GetChungTuNghiepVuKhacChiTiet(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertChungTuNghiepVuKhacChiTiet = createAsyncThunk(
  "ChungTuNghiepVuKhac/UpsertChungTuNghiepVuKhacChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await ChungTuNghiepVuKhacService.UpsertChungTuNghiepVuKhacChiTiet(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteChungTuNghiepVuKhacChiTiet = createAsyncThunk(
  "ChungTuNghiepVuKhac/DeleteChungTuNghiepVuKhacChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response =
        await ChungTuNghiepVuKhacService.DeleteChungTuNghiepVuKhacChiTiet(
          payload.id
        );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const ExportExcel = createAsyncThunk(
  "ChungTuNghiepVuKhac/ExportExcel",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ChungTuNghiepVuKhacService.ExportExcel(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  ChungTuNghiepVuKhacList: [],
  ChungTuNghiepVuKhacDetail: {},
  ChungTuNghiepVuKhacChiTietList: [],
};

const ChungTuNghiepVuKhacTienSlice = createSlice({
  name: "ChungTuNghiepVuKhac",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetChungTuNghiepVuKhacChiTiet
      .addCase(GetChungTuNghiepVuKhacChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChungTuNghiepVuKhacChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.ChungTuNghiepVuKhacChiTietList = action.payload;
      })
      .addCase(GetChungTuNghiepVuKhacChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetChungTuNghiepVuKhac
      .addCase(GetChungTuNghiepVuKhac.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChungTuNghiepVuKhac.fulfilled, (state, action) => {
        state.loading = false;
        state.ChungTuNghiepVuKhacDetail = action.payload;
      })
      .addCase(GetChungTuNghiepVuKhac.rejected, (state) => {
        state.loading = false;
      })
      //GetAllChungTuNghiepVuKhac
      .addCase(GetAllChungTuNghiepVuKhac.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllChungTuNghiepVuKhac.fulfilled, (state, action) => {
        state.loading = false;
        state.ChungTuNghiepVuKhacList = action.payload;
      })
      .addCase(GetAllChungTuNghiepVuKhac.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = ChungTuNghiepVuKhacTienSlice.actions;

export default ChungTuNghiepVuKhacTienSlice.reducer;
