import { DE_NGHI_THU_TIEN_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const DeNghiThuTienService = {};

DeNghiThuTienService.GetAllDeNghiThuTien = function (data) {
  return axiosInstance.post(
    `${DE_NGHI_THU_TIEN_API}/get-all-de-nghi-thu-tien`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.GetDeNghiThuTien = function (data) {
  return axiosInstance.get(
    `${DE_NGHI_THU_TIEN_API}/get-de-nghi-thu-tien?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.UpsertDeNghiThuTien = function (data) {
  return axiosInstance.put(
    `${DE_NGHI_THU_TIEN_API}/up-sert-de-nghi-thu-tien`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.DeleteDeNghiThuTien = function (data) {
  return axiosInstance.delete(
    `${DE_NGHI_THU_TIEN_API}/xoa-de-nghi-thu-tien?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.IsTongHopTrinh = function (data) {
  return axiosInstance.post(
    `${DE_NGHI_THU_TIEN_API}/trinh-de-nghi-thanh-toan`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.GetDeNghiThuTienChiTiet = function (data) {
  return axiosInstance.get(
    `${DE_NGHI_THU_TIEN_API}/get-de-nghi-thu-tien-chi-tiet?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.GetChiDeNghiThuTienChiTiet = function (data) {
  return axiosInstance.get(
    `${DE_NGHI_THU_TIEN_API}/get-chi-de-nghi-thu-tien-chi-tiet?id=${data.id}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.UpsertDeNghiThuTienChiTiet = function (data) {
  return axiosInstance.put(
    `${DE_NGHI_THU_TIEN_API}/up-sert-de-nghi-thu-tien-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.DeleteDeNghiThuTienChiTiet = function (data) {
  return axiosInstance.delete(
    `${DE_NGHI_THU_TIEN_API}/xoa-de-nghi-thu-tien-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

DeNghiThuTienService.ExportPhieuFinance = function (data) {
  return axiosInstance.post(`${DE_NGHI_THU_TIEN_API}/export-excel`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

export default DeNghiThuTienService;
