import { ASSET_DIEU_CHUYEN_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const AssetDieuChuyenService = {};

AssetDieuChuyenService.GetAllAssetDieuChuyen = function (data) {
  return axiosInstance.post(
    `${ASSET_DIEU_CHUYEN_API}/get-all-dieu-chuyen`,
    data,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

AssetDieuChuyenService.GetAssetDieuChuyen = function (data) {
  return axiosInstance.get(
    `${ASSET_DIEU_CHUYEN_API}/get-dieu-chuyen?id=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

AssetDieuChuyenService.UpsertAssetDieuChuyen = function (data) {
  return axiosInstance.put(
    `${ASSET_DIEU_CHUYEN_API}/up-sert-dieu-chuyen`,
    data,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

AssetDieuChuyenService.DeleteAssetDieuChuyen = function (data) {
  return axiosInstance.delete(
    `${ASSET_DIEU_CHUYEN_API}/xoa-dieu-chuyen?id=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

AssetDieuChuyenService.GetAssetDieuChuyenChiTiet = function (data) {
  return axiosInstance.get(
    `${ASSET_DIEU_CHUYEN_API}/get-dieu-chuyen-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

AssetDieuChuyenService.UpsertAssetDieuChuyenChiTiet = function (data) {
  return axiosInstance.put(
    `${ASSET_DIEU_CHUYEN_API}/up-sert-dieu-chuyen-chi-tiet`,
    data,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

AssetDieuChuyenService.DeleteAssetDieuChuyenChiTiet = function (data) {
  return axiosInstance.delete(
    `${ASSET_DIEU_CHUYEN_API}/xoa-dieu-chuyen-chi-tiet?id=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

AssetDieuChuyenService.GetListTaiSanByLoaiDC = function (data) {
  return axiosInstance.post(
    `${ASSET_DIEU_CHUYEN_API}/get-list-tai-san-theo-loai`,
    data,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

AssetDieuChuyenService.GetAllTaiSan = function () {
  return axiosInstance.get(`${ASSET_DIEU_CHUYEN_API}/get-all-list-tai-san`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

export default AssetDieuChuyenService;
