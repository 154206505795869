import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import ChuyenNoiBoService from "services/Finance/ChuyenNoiBoService";

export const GetAllChuyenNoiBo = createAsyncThunk(
  "ChuyenNoiBo/GetAllChuyenNoiBo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ChuyenNoiBoService.GetAllChuyenNoiBo(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetChuyenNoiBo = createAsyncThunk(
  "ChuyenNoiBo/GetChuyenNoiBo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ChuyenNoiBoService.GetChuyenNoiBo(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertChuyenNoiBo = createAsyncThunk(
  "ChuyenNoiBo/UpsertChuyenNoiBo",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ChuyenNoiBoService.UpsertChuyenNoiBo(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const ExportChuyenNoiBo = createAsyncThunk(
  "ChuyenNoiBo/ExportChuyenNoiBo",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ChuyenNoiBoService.ExportChuyenNoiBo(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteChuyenNoiBo = createAsyncThunk(
  "ChuyenNoiBo/DeleteChuyenNoiBo",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ChuyenNoiBoService.DeleteChuyenNoiBo(payload.id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetChuyenNoiBoChiTiet = createAsyncThunk(
  "ChuyenNoiBo/GetChuyenNoiBoChiTiet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ChuyenNoiBoService.GetChuyenNoiBoChiTiet(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertChuyenNoiBoChiTiet = createAsyncThunk(
  "ChuyenNoiBo/UpsertChuyenNoiBoChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ChuyenNoiBoService.UpsertChuyenNoiBoChiTiet(data);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteChuyenNoiBoChiTiet = createAsyncThunk(
  "ChuyenNoiBo/DeleteChuyenNoiBoChiTiet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ChuyenNoiBoService.DeleteChuyenNoiBoChiTiet(
        payload.id
      );
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  ChuyenNoiBoList: [],
  ChuyenNoiBoDetail: {},
  ChuyenNoiBoChiTietList: [],
};

const chuyenNoiBoSlice = createSlice({
  name: "chuyenNoiBo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetChuyenNoiBoChiTiet
      .addCase(GetChuyenNoiBoChiTiet.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChuyenNoiBoChiTiet.fulfilled, (state, action) => {
        state.loading = false;
        state.ChuyenNoiBoChiTietList = action.payload;
      })
      .addCase(GetChuyenNoiBoChiTiet.rejected, (state) => {
        state.loading = false;
      })
      //GetChuyenNoiBo
      .addCase(GetChuyenNoiBo.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChuyenNoiBo.fulfilled, (state, action) => {
        state.loading = false;
        state.ChuyenNoiBoDetail = action.payload;
      })
      .addCase(GetChuyenNoiBo.rejected, (state) => {
        state.loading = false;
      })
      //GetAllChuyenNoiBo
      .addCase(GetAllChuyenNoiBo.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllChuyenNoiBo.fulfilled, (state, action) => {
        state.loading = false;
        state.ChuyenNoiBoList = action.payload;
      })
      .addCase(GetAllChuyenNoiBo.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { showLoading, setHisInfo } = chuyenNoiBoSlice.actions;

export default chuyenNoiBoSlice.reducer;
