import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import BaoCaoThuChiService from "services/Finance/BaoCaoThuChiService";

export const GetSoDuTaiKhoan = createAsyncThunk(
  "baoCaoThuChiSlice/GetSoDuTaiKhoan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoThuChiService.GetSoDuTaiKhoan(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const ExportTongHopSoDu = createAsyncThunk(
  "baoCaoThuChiSlice/ExportTongHopSoDu",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThuChiService.ExportSoDu(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetBaoCaoCongNo = createAsyncThunk(
  "baoCaoThuChiSlice/GetBaoCaoCongNo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoThuChiService.GetBaoCaoCongNo(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetChiTietCongNo = createAsyncThunk(
  "baoCaoThuChiSlice/GetChiTietCongNo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await BaoCaoThuChiService.GetChiTietCongNo(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const ExportBaoCaoCongNo = createAsyncThunk(
  "baoCaoThuChiSlice/ExportBaoCaoCongNo",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await BaoCaoThuChiService.ExportBaoCaoCongNo(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  SoDuTaiKhoanList: [],
  BaoCaoCongNoList: [],
  ChiTietCongNoList: [],
};

const baoCaoThuChiSlice = createSlice({
  name: "baoCaoThuChiSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GetChiTietCongNo
      .addCase(GetChiTietCongNo.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChiTietCongNo.fulfilled, (state, action) => {
        state.loading = false;
        state.ChiTietCongNoList = action.payload;
      })
      .addCase(GetChiTietCongNo.rejected, (state) => {
        state.loading = false;
      }) //
      //GetBaoCaoCongNo
      .addCase(GetBaoCaoCongNo.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetBaoCaoCongNo.fulfilled, (state, action) => {
        state.loading = false;
        state.BaoCaoCongNoList = action.payload;
      })
      .addCase(GetBaoCaoCongNo.rejected, (state) => {
        state.loading = false;
      }) //
      .addCase(GetSoDuTaiKhoan.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSoDuTaiKhoan.fulfilled, (state, action) => {
        state.loading = false;
        state.SoDuTaiKhoanList = action.payload;
      })
      .addCase(GetSoDuTaiKhoan.rejected, (state) => {
        state.loading = false;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const {} = baoCaoThuChiSlice.actions;

export default baoCaoThuChiSlice.reducer;
