import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import DmNhaSanXuatService from "services/Asset/Settings/DmNhaSanXuatService";

export const getAll = createAsyncThunk(
  "nhaSanXuat/getAll",
  async ( _,{ rejectWithValue }) => {
    try {
      const response = await DmNhaSanXuatService.Get();
      return response.data.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);


export const create = createAsyncThunk(
  "nhaSanXuat/create",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await DmNhaSanXuatService.Create(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);



const initialState = {
  loading: false,
  nhasanxuat: [],
};

const dmNhaSanXuatSlice = createSlice({
  name: "nhaSanXuat",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAll.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.loading = false;
        state.nhasanxuat = action.payload;
      })
      .addCase(getAll.rejected, (state) => {
        state.loading = false;
      })

      .addCase(create.pending, (state) => {
        state.loading = true;
      })
      .addCase(create.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(create.rejected, (state) => {
        state.loading = false;
      })
     

      
  },
});
// eslint-disable-next-line no-empty-pattern
export const {  } = dmNhaSanXuatSlice.actions;

export default dmNhaSanXuatSlice.reducer;
