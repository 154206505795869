import axiosInstance from "auth/FetchInterceptor";
import {
  DMXUAT_XU_API,
} from "constants/ApiConstant";

const XuatXuTaiSanService = {};

XuatXuTaiSanService.Get = function () {
  return axiosInstance.get(`${DMXUAT_XU_API}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

XuatXuTaiSanService.Create = function (data) {
  return axiosInstance.post(`${DMXUAT_XU_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};




export default XuatXuTaiSanService;
