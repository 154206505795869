import axiosInstance from "auth/FetchInterceptor";
import {
    DE_NGHI_THANH_LY_API
} from "constants/ApiConstant";

const HuyTaiSanService = {};



HuyTaiSanService.CreateDeNghiThanhLy = function (data) {
  return axiosInstance.post(`${DE_NGHI_THANH_LY_API}/create`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

HuyTaiSanService.GetTaiSanCoTheDeNghiThanhLyByTaiSanID = function (id) {
  return axiosInstance.get(`${DE_NGHI_THANH_LY_API}/search?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

HuyTaiSanService.GetPhieuDeNghiThanhLyById = function (id) {
    return axiosInstance.get(`${DE_NGHI_THANH_LY_API}?id=${id}`, {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    });
  };

  HuyTaiSanService.UpdatePhieuDeNghiThanhLy = function (data) {
    return axiosInstance.put(`${DE_NGHI_THANH_LY_API}`,data, {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    });
  };

  HuyTaiSanService.DeletePhieuDeNgHiThanhLyById = function (id) {
    return axiosInstance.delete(`${DE_NGHI_THANH_LY_API}?id=${id}`, {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    });
  };

  HuyTaiSanService.DeleteChiTietDeNgHiThanhLyById = function (id) {
    return axiosInstance.delete(`${DE_NGHI_THANH_LY_API}/chi-tiet?id=${id}`, {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    });
  };

  HuyTaiSanService.GetDanhSachPhieuDeNghiThanhLy = function (data) {
    return axiosInstance.post(`${DE_NGHI_THANH_LY_API}/search`,data, {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    });
  };

  HuyTaiSanService.GetDanhSachTaiSanCanThanhLy = function (data) {
    return axiosInstance.post(`${DE_NGHI_THANH_LY_API}/can-thanh-ly`,data, {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    });
  };

  HuyTaiSanService.ThanhLy = function (data) {
    return axiosInstance.post(`${DE_NGHI_THANH_LY_API}/thanh-ly`,data, {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    });
  };











export default HuyTaiSanService;
