import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import ThietLapNghiLeService from "services/thietLapKhac/NghiLeService";

export const SearchThietLapNghiLe = createAsyncThunk(
  "nghiLe/SearchThietLapNghiLe",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ThietLapNghiLeService.search(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetByIdThietLapNghiLe = createAsyncThunk(
  "nghiLe/GetByIdThietLapNghiLe",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ThietLapNghiLeService.Detail(id);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertThietLapNghiLe = createAsyncThunk(
  "nghiLe/UpsertThietLapNghiLe",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, ...payload } = data;
      const response = await ThietLapNghiLeService.UpSert(payload);
      if (onSuccess) onSuccess();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const DeleteThietLapNghiLe = createAsyncThunk(
  "nghiLe/DeleteThietLapNghiLe",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await ThietLapNghiLeService.Delete(id);
      if (onSuccess) onSuccess();
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  list: [],
  detail: [],
};

const nghiLeSlice = createSlice({
  name: "nghiLe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SearchThietLapNghiLe.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchThietLapNghiLe.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(SearchThietLapNghiLe.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetByIdThietLapNghiLe.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetByIdThietLapNghiLe.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = {
          ...action.payload,
          date: moment(action.payload.startTime),
          time: [
            moment(action.payload.startTime),
            moment(action.payload.endTime),
          ],
        };
      })
      .addCase(GetByIdThietLapNghiLe.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(UpsertThietLapNghiLe.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpsertThietLapNghiLe.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpsertThietLapNghiLe.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default nghiLeSlice.reducer;
