import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import KiemKeService from "services/Asset/KiemKe/KiemKeService";
import Utils from "utils";

export const searchGrid = createAsyncThunk(
  "audit/search-grid",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.SearchGrid(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getKiemKeById = createAsyncThunk(
  "audit/getKiemKeById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await KiemKeService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createAudit = createAsyncThunk(
  "audit/create",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.CreateAudit(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updateAudit = createAsyncThunk(
  "audit/update",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.UpdateAudit(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteAudit = createAsyncThunk(
  "audit/delete",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await KiemKeService.DeleteAudit(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getAssetAuditList = createAsyncThunk(
  "audit/getTaiSanKiemKeList",
  async (id, { rejectWithValue }) => {
    try {
      const response = await KiemKeService.GetAssetAudit(id);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getAssetAuditDoingList = createAsyncThunk(
  "audit/getTaiSanDangKiemKeList",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, auditId } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.GetAssetAuditDoing(auditId);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getAssetAuditDoneList = createAsyncThunk(
  "audit/getTaiSanDaKiemKeList",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, auditId } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.GetAssetAuditDone(auditId);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updateLatestAuditData = createAsyncThunk(
  "audit/updateLatestAuditData",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.UpdateLatestAuditData(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const startAudit = createAsyncThunk(
  "audit/startAudit",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.StartAudit(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const completeAudit = createAsyncThunk(
  "audit/completeAudit",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.CompleteAudit(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const cancelAudit = createAsyncThunk(
  "audit/cancelAudit",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.CancelAudit(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const reInventory = createAsyncThunk(
  "audit/reInventory",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.ReInventory(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updateAssetAudit = createAsyncThunk(
  "audit/updateAssetAudit",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.UpdateAssetAudit(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDiffAssetByCode = createAsyncThunk(
  "audit/getDiffAssetByCode",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.GetDiffAssetByCode(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDiffAssetByQuantity = createAsyncThunk(
  "audit/getDiffAssetByQuantity",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.GetDiffAssetByQuantity(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const exportAssetAudit = createAsyncThunk(
  "audit/exportAssetAudit",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.ExportTaiSanKiemKe(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const importAssetAudit = createAsyncThunk(
  "audit/importAssetAudit",
  async (data, { rejectWithValue }) => {
    const { formData, onUploadSuccess, onUploadFailed } = data;
    try {
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeService.ImportTaiSanKiemKe(formData);
      if (onUploadSuccess) onUploadSuccess(response.data);
      return response.data;
    } catch (err) {
      if (onUploadFailed) onUploadFailed(err.response.data);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  kiemKeList: [],
  kiemKeDetail: {
    data: null,
    loading: false,
  },
  taiSanKiemKeList: [],
  taiSanDangKiemKeList: [],
  taiSanDaKiemKeList: [],
  taiSanChenhLechTheoMaList: [],
  taiSanChenhLechTheoSoLuongList: [],
};

const kiemKeSlice = createSlice({
  name: "kiemke",
  initialState,
  reducers: {
    // setKiemKeAction: (state, payload) => {
    //   state.kiemKeDetail = {
    //     data: null,
    //     loading: false,
    //   };
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchGrid.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchGrid.fulfilled, (state, action) => {
        state.loading = false;
        state.kiemKeList = action.payload;
      })
      .addCase(searchGrid.rejected, (state) => {
        state.loading = false;
        state.kiemKeDetail = {
          data: null,
          loading: false,
        };
      })
      .addCase(getKiemKeById.pending, (state) => {
        state.loading = true;
        // state.kiemKeDetail = {
        //   ...state.kiemKeDetail,
        //   loading: true,
        // };
      })
      .addCase(getKiemKeById.fulfilled, (state, action) => {
        state.loading = false;
        state.kiemKeDetail = {
          data: {
            ...action.payload,
            toDate: Utils.convertMomentToDate(action.payload.toDate),
          },
          loading: false,
        };
      })
      .addCase(getKiemKeById.rejected, (state) => {
        state.loading = false;
        // state.kiemKeDetail = {
        //   ...state.kiemKeDetail,
        //   loading: false,
        // };
      })
      .addCase(createAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createAudit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAudit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteAudit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAssetAuditList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssetAuditList.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanKiemKeList = action.payload;
      })
      .addCase(getAssetAuditList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAssetAuditDoingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssetAuditDoingList.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanDangKiemKeList = action.payload.pageDatas;
      })
      .addCase(getAssetAuditDoingList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAssetAuditDoneList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssetAuditDoneList.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanDaKiemKeList = action.payload;
      })
      .addCase(getAssetAuditDoneList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateLatestAuditData.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLatestAuditData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateLatestAuditData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(startAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(startAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(startAudit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(completeAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(completeAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(completeAudit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(cancelAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(cancelAudit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(reInventory.pending, (state) => {
        state.loading = true;
      })
      .addCase(reInventory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(reInventory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAssetAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAssetAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAssetAudit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDiffAssetByCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDiffAssetByCode.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanChenhLechTheoMaList = action.payload;
      })
      .addCase(getDiffAssetByCode.rejected, (state) => {
        state.loading = true;
      })
      .addCase(getDiffAssetByQuantity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDiffAssetByQuantity.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanChenhLechTheoSoLuongList = action.payload;
      })
      .addCase(getDiffAssetByQuantity.rejected, (state) => {
        state.loading = true;
      })
      .addCase(exportAssetAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportAssetAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(exportAssetAudit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(importAssetAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(importAssetAudit.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(importAssetAudit.rejected, (state) => {
        state.loading = false;
      });
  },
});

// export const { setKiemKeAction } = kiemKeSlice.actions;

export default kiemKeSlice.reducer;
