import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import HuyTaiSanService from "services/Asset/HuyTaiSan/HuyTaiSanService";

export const createHuyTaiSan = createAsyncThunk(
  "huyTaiSan/createHuyTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await HuyTaiSanService.CreateHuyTaiSan(data);
      if (response.data) {
        if (onSuccess) onSuccess(response);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getThongTinPhieu = createAsyncThunk(
  "huyTaiSan/getThongTinPhieu",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await HuyTaiSanService.GetThongTinPhieu(id);
      if (response.data) {
        if (onSuccess) onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getTaiSanCoTheHuy = createAsyncThunk(
  "huyTaiSan/getTaiSanCoTheHuy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await HuyTaiSanService.GetTaiSanCoTheHuyByID(id);
      if (response.data) {
        if (onSuccess) onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getDanhSachPhieuBaoHuy = createAsyncThunk(
  "huyTaiSan/getDanhSachPhieuBaoHuy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await HuyTaiSanService.GetDanhSachPhieuBaoHuy(data);
      if (response.data) {
        if (onSuccess) onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deletePhieuHuyTaiSan = createAsyncThunk(
  "huyTaiSan/deletePhieuHuyTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess,id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await HuyTaiSanService.DeletePhieuHuyTaiSan(id);
      if (response.data) {
        if (onSuccess) onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getDanhSachTaiSanDaHuy = createAsyncThunk(
  "huyTaiSan/getDanhSachTaiSanDaHuy",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await HuyTaiSanService.DanhSachTaiSanDaHuy(data);
      if (response.data) {
        if (onSuccess) onSuccess(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  huyTaiSanDetail: {},
  danhSachTaiSanDeNghiHuy: [],
  danhSachTaiSanDaHuy:[]
};

const HuyTaiSanSlice = createSlice({
  name: "huyTaiSan",
  initialState,
  reducers: {
    resetdanhSachTaiSanDeNghiHuy: (state, action) => {
      state.danhSachTaiSanDeNghiHuy = [];
    },
    resethuyTaiSanDetail: (state, action) => {
      state.huyTaiSanDetail = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createHuyTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createHuyTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.huyTaiSanDetail = action.payload;
      })
      .addCase(createHuyTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThongTinPhieu.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThongTinPhieu.fulfilled, (state, action) => {
        state.loading = false;
        state.huyTaiSanDetail = action.payload.data;
        state.danhSachTaiSanDeNghiHuy = action.payload?.chiTiet;
      })
      .addCase(getThongTinPhieu.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTaiSanCoTheHuy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSanCoTheHuy.fulfilled, (state, action) => {
        state.loading = false;
        state.danhSachTaiSanDeNghiHuy = action.payload;
      })
      .addCase(getTaiSanCoTheHuy.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDanhSachPhieuBaoHuy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachPhieuBaoHuy.fulfilled, (state, action) => {
        state.loading = false;
        state.danhSachTaiSanDeNghiHuy = action.payload;
      })
      .addCase(getDanhSachPhieuBaoHuy.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDanhSachTaiSanDaHuy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDanhSachTaiSanDaHuy.fulfilled, (state, action) => {
        state.loading = false;
        state.danhSachTaiSanDaHuy = action.payload;
      })
      .addCase(getDanhSachTaiSanDaHuy.rejected, (state) => {
        state.loading = false;
      })
      ;
  },
});
export const { resetdanhSachTaiSanDeNghiHuy, resethuyTaiSanDetail } = HuyTaiSanSlice.actions;

export default HuyTaiSanSlice.reducer;
