import axiosInstance from "auth/FetchInterceptor";
import { KIEM_KE_API } from "constants/ApiConstant";

const KiemKeService = {};

KiemKeService.SearchGrid = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/search-grid`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.GetById = function (data) {
  return axiosInstance.get(`${KIEM_KE_API}/get-by-id?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.CreateAudit = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/create-audit`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.UpdateAudit = function (data) {
  return axiosInstance.put(`${KIEM_KE_API}/update-audit`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.DeleteAudit = function (id) {
  return axiosInstance.delete(`${KIEM_KE_API}/delete-audit?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.GetAssetAudit = function (data) {
  return axiosInstance.get(`${KIEM_KE_API}/get-asset-audit?auditId=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.GetAssetAuditDoing = function (data) {
  return axiosInstance.get(
    `${KIEM_KE_API}/get-asset-audit-doing?auditId=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

KiemKeService.GetAssetAuditDone = function (data) {
  return axiosInstance.get(
    `${KIEM_KE_API}/get-asset-audit-done?auditId=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

KiemKeService.UpdateLatestAuditData = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/update-latest-audit-data`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.StartAudit = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/start-audit`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.CompleteAudit = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/complete-audit`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.CancelAudit = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/cancel-audit`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.UpdateAssetAudit = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/update-asset-audit`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.ReInventory = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/re-inventory`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.GetDiffAssetByCode = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/diff-asset-by-code`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.GetDiffAssetByQuantity = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/diff-asset-by-quantity`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.ExportTaiSanKiemKe = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/export-asset-audit`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

KiemKeService.ImportTaiSanKiemKe = function (data) {
  return axiosInstance.post(`${KIEM_KE_API}/import-asset-audit`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

export default KiemKeService;
