import { VI_TRI_TAI_SAN_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const ViTriTaiSanService = {};

ViTriTaiSanService.GetViTriTaiSan = function () {
  return axiosInstance.get(`${VI_TRI_TAI_SAN_API}/get-vi-tri-tai-san`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
ViTriTaiSanService.GetTreeViTriTaiSan = function (data) {
  return axiosInstance.post(
    `${VI_TRI_TAI_SAN_API}/get-tree-vi-tri-tai-san`,
    data,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};
ViTriTaiSanService.DanhMucViTriTaiSan = function (data) {
  return axiosInstance.get(`${VI_TRI_TAI_SAN_API}/get-dm-vi-tri-tai-san`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

ViTriTaiSanService.GetViTriTaiSanById = function (data) {
  return axiosInstance.get(
    `${VI_TRI_TAI_SAN_API}/get-vi-tri-tai-san-by-id?id=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

ViTriTaiSanService.CreateViTriTaiSan = function (data) {
  return axiosInstance.put(
    `${VI_TRI_TAI_SAN_API}/create-vi-tri-tai-san`,
    data,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

ViTriTaiSanService.UpdateViTriTaiSan = function (data) {
  return axiosInstance.put(
    `${VI_TRI_TAI_SAN_API}/update-vi-tri-tai-san`,
    data,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

ViTriTaiSanService.DeleteViTriTaiSan = function (data) {
  return axiosInstance.delete(
    `${VI_TRI_TAI_SAN_API}/delete-vi-tri-tai-san?id=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

ViTriTaiSanService.GetListPhongBan = function (data) {
  return axiosInstance.get(`${VI_TRI_TAI_SAN_API}/get-list-phong-ban`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

ViTriTaiSanService.GetListPhongBanDonViSuDung = function (data) {
  return axiosInstance.get(
    `${VI_TRI_TAI_SAN_API}/get-list-phong-ban-by-don-vi?DonViId=${data}`,
    {
      baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
    }
  );
};

export default ViTriTaiSanService;
