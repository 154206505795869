import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import KiemKeMemberService from "services/Asset/KiemKe/KiemKeMemberSlice";

export const getMemberList = createAsyncThunk(
  "audit/getMemberList",
  async (id, { rejectWithValue }) => {
    try {
      const response = await KiemKeMemberService.GetMemberList(id);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getMemberById = createAsyncThunk(
  "audit/getMemberById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await KiemKeMemberService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createMember = createAsyncThunk(
  "audit/createMember",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeMemberService.CreateMember(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updateMember = createAsyncThunk(
  "audit/updateMember",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await KiemKeMemberService.UpdateMember(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const deleteMember = createAsyncThunk(
  "audit/deleteMember",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await KiemKeMemberService.DeleteMember(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  memberList: [],
  memberDetail: [],
};

const kiemKeMemberSlice = createSlice({
  name: "kiemKeMember",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMemberList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMemberList.fulfilled, (state, action) => {
        state.loading = false;
        state.memberList = action.payload;
      })
      .addCase(getMemberList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMemberById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMemberById.fulfilled, (state, action) => {
        state.loading = false;
        state.memberDetail = action.payload;
      })
      .addCase(getMemberById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMember.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createMember.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateMember.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteMember.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default kiemKeMemberSlice.reducer;
