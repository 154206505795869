import { PREFIX } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const ThietLapNghiLeService = {};

const THIET_LAP_URL = `${PREFIX}/ThietLapNghiLe`;

ThietLapNghiLeService.search = function (data) {
  return axiosInstance.post(`${THIET_LAP_URL}/search`, data);
};
ThietLapNghiLeService.UpSert = function (data) {
  return axiosInstance.post(`${THIET_LAP_URL}/up-sert`, data);
};
ThietLapNghiLeService.Detail = function (id) {
  return axiosInstance.get(`${THIET_LAP_URL}/detail?id=${id}`);
};
ThietLapNghiLeService.Delete = function (id) {
  return axiosInstance.delete(`${THIET_LAP_URL}/delete?id=${id}`);
};

export default ThietLapNghiLeService;
