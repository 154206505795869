import axiosInstance from "auth/FetchInterceptor";
import { LINH_KIEN_API } from "constants/ApiConstant";

const LinhKienService = {};

LinhKienService.search = function (data) {
  return axiosInstance.post(`${LINH_KIEN_API}/search`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LinhKienService.getAllLinhKien = function (data) {
  return axiosInstance.post(`${LINH_KIEN_API}/search`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LinhKienService.upsert = function (data) {
  return axiosInstance.post(`${LINH_KIEN_API}/upsert`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LinhKienService.upsertOther = function (data) {
  return axiosInstance.post(`${LINH_KIEN_API}/upsert-other`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LinhKienService.delete = function (id) {
  return axiosInstance.delete(`${LINH_KIEN_API}/delete?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LinhKienService.deleteByIds = function (ids) {
  return axiosInstance.put(`${LINH_KIEN_API}/delete-by-ids`, ids, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LinhKienService.getById = function (id) {
  return axiosInstance.get(`${LINH_KIEN_API}/detail?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LinhKienService.getAllQuaTrinhSD = function (id) {
  return axiosInstance.get(`${LINH_KIEN_API}/QuaTrinhSuDung/get-all?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

export default LinhKienService;
