import axiosInstance from "auth/FetchInterceptor";
import { PREFIX } from "constants/ApiConstant";
const CrmVoteService = {};

CrmVoteService.search = function (data) {
  return axiosInstance.post(`${PREFIX}/VoteManagement/search`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CrmVoteService.getById = function (id) {
  return axiosInstance.get(`${PREFIX}/VoteManagement/get-by-id?id=${id}`, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

CrmVoteService.upSert = function (data) {
  return axiosInstance.post(`${PREFIX}/VoteManagement/up-sert`, data, {
    baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
  });
};

export default CrmVoteService;
