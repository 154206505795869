import axiosInstance from "auth/FetchInterceptor";
import {
  HUY_TAI_SAN_API
} from "constants/ApiConstant";

const HuyTaiSanService = {};



HuyTaiSanService.CreateHuyTaiSan = function (data) {
  return axiosInstance.post(`${HUY_TAI_SAN_API}/create`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

HuyTaiSanService.GetThongTinPhieu = function (id) {
  return axiosInstance.get(`${HUY_TAI_SAN_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
HuyTaiSanService.GetTaiSanCoTheHuyByID = function (id) {
  return axiosInstance.get(`${HUY_TAI_SAN_API}/co-the-huy?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

HuyTaiSanService.GetDanhSachPhieuBaoHuy = function (data) {
  return axiosInstance.post(`${HUY_TAI_SAN_API}/search`,data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

HuyTaiSanService.DeletePhieuHuyTaiSan = function (id) {
  return axiosInstance.delete(`${HUY_TAI_SAN_API}?id=${id}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

HuyTaiSanService.DanhSachTaiSanDaHuy = function (data) {
  return axiosInstance.post(`${HUY_TAI_SAN_API}/search-da-huy`, data,{
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};










export default HuyTaiSanService;
