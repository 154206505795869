import axiosInstance from "auth/FetchInterceptor";
import {
  GET_ALL_GHI_NHAN_API,
  UPDATE_GHI_NHAN_API,
  GET_CATE_GHI_NHAN_API,
  GET_GHI_NHAN_BY_ID_API,
  GET_HISTORY_GHI_NHAN_BY_ID_API,
  UP_SERT_GHI_NHAN_API,
} from "constants/ApiConstant";

const GhiNhanYeuCauService = {};

GhiNhanYeuCauService.GetCategory = function () {
  return axiosInstance.get(`${GET_CATE_GHI_NHAN_API}`);
};

GhiNhanYeuCauService.UpsertGhiNhan = function (data) {
  return axiosInstance.post(`${UP_SERT_GHI_NHAN_API}`, data);
};

GhiNhanYeuCauService.UpdateGhiNhan = function (data) {
  return axiosInstance.put(`${UPDATE_GHI_NHAN_API}`, data);
};

GhiNhanYeuCauService.GetAllGhiNhan = function (data) {
  return axiosInstance.post(`${GET_ALL_GHI_NHAN_API}`, data);
};
GhiNhanYeuCauService.GetById = function (data) {
  return axiosInstance.get(`${GET_GHI_NHAN_BY_ID_API}?id=${data}`);
};
GhiNhanYeuCauService.GetHistoryById = function (data) {
  return axiosInstance.get(`${GET_HISTORY_GHI_NHAN_BY_ID_API}?id=${data}`);
};

export default GhiNhanYeuCauService;
