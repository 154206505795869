import { BAO_CAO_TAI_CHINH_API } from "constants/ApiConstant";
import axiosInstance from "auth/FetchInterceptor";
const BaoCaoThuChiService = {};

BaoCaoThuChiService.GetSoDuTaiKhoan = function (data) {
  return axiosInstance.post(
    `${BAO_CAO_TAI_CHINH_API}/bao-cao-so-du-tai-khoan`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

BaoCaoThuChiService.ExportSoDu = function (data) {
  return axiosInstance.post(`${BAO_CAO_TAI_CHINH_API}/export-excel`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

BaoCaoThuChiService.GetBaoCaoCongNo = function (data) {
  return axiosInstance.post(`${BAO_CAO_TAI_CHINH_API}/bao-cao-cong-no`, data, {
    baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
  });
};

BaoCaoThuChiService.GetChiTietCongNo = function (data) {
  return axiosInstance.get(
    `${BAO_CAO_TAI_CHINH_API}/chi-tiet-cong-no?KhachHangId=${data}`,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

BaoCaoThuChiService.ExportBaoCaoCongNo = function (data) {
  return axiosInstance.post(
    `${BAO_CAO_TAI_CHINH_API}/export-excel-bao-cao-cong-no`,
    data,
    {
      baseURL: process.env.REACT_APP_FINANCE_ENDPOINT_URL,
    }
  );
};

export default BaoCaoThuChiService;
